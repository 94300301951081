import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NewsResponse, RoadmapResponse} from "./responses";
import {environment} from "../../environments/environment";

@Injectable()
export class LandingService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getRoadmap(): Observable<RoadmapResponse> {
        return this.http.get<RoadmapResponse>(`${environment.apiUrl}/landing/roadmap`)
    }

    voteRoadmapItem(featureId: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/landing/roadmap/${featureId}/vote`, {})
    }

    getNews(): Observable<NewsResponse> {
        return this.http.get<NewsResponse>(`${environment.apiUrl}/landing/news`)
    }
}

