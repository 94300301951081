import {Component} from '@angular/core';
import {Router, RouterLink, RouterModule, RouterOutlet} from '@angular/router';
import {BreakpointObserver} from "@angular/cdk/layout";
import {MatButtonModule, MatIconButton} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-root',
    template: `
        <div class="landing-page">

            <!-- Header -->
            <header style="min-height: 50px">
                <button mat-icon-button *ngIf="isSmallScreen" [matMenuTriggerFor]="menu">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="menu">
                    <button mat-menu-item routerLink="/how-tos" style="color: white" i18n>How-tos</button>
                    <button mat-menu-item routerLink="/features" style="color: white" i18n>Features</button>
                    <button mat-menu-item routerLink="/roadmap" style="color: white" i18n>Roadmap</button>
                    <button mat-menu-item routerLink="/contact" style="color: white" i18n>Contact</button>
                </mat-menu>
                <div class="row align-center">
                    <div routerLink="/" class="logo row align-center" style="cursor: pointer">
                        <img src="/assets/logo.png" width="24" height="24" alt="Logo"/>
                        <span class="logo" style="margin-left: 16px">Ludoya</span>
                    </div>
                    <div class="nav" *ngIf="!isSmallScreen">
                        <a routerLink="/how-tos" i18n>How-tos</a>
                        <a routerLink="/features" i18n>Features</a>
                        <a routerLink="/roadmap" i18n>Roadmap</a>
                        <a routerLink="/contact" i18n>Contact</a>
                    </div>
                </div>
            </header>

            <router-outlet></router-outlet>

            <!-- Footer -->
            <footer>
                <!--<div class="newsletter">
                  <h3>Stay Updated</h3>
                  <p>Join our newsletter for the latest news, features, and updates.</p>
                  <input type="email" placeholder="Enter your email"/>
                  <button>Subscribe</button>
                </div>-->
                <div class="links">
                    <a routerLink="terms-of-service">Terms of Service</a> |
                    <a routerLink="privacy-policy">Privacy Policy</a>
                </div>
                <div class="social-icons">
                    <!-- Placeholder for social icons -->
                    <a href="https://twitter.com/ludoyapp" target="_blank"><img src="/assets/twitter.svg" alt="X"/></a>
                    <a href="https://discord.gg/QG2yNYypVn" target="_blank"><img src="/assets/discord.svg" alt="Discord"/></a>
                    <a href="https://t.me/ludoyapp" target="_blank"><img src="/assets/telegram.png" alt="Telegram"/></a>
                </div>
            </footer>
        </div>
    `,
    styles: [`
        /* Header */
        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 16px;
            background-color: #222;
            color: white;

            .logo {
                font-size: 1.2em;
                font-weight: bold;
            }

            .nav {
                margin-left: 20px;
            }

            .nav a {
                margin: 0 10px;
                font-size: 1.2rem;
                font-weight: normal;
                color: #ccc;

                &:hover {
                    color: white;
                }
            }
        }

        footer {
            padding: 20px 50px;
            background-color: #eee;

            .newsletter {
                text-align: center;
            }

            .links {
                text-align: center;
                margin-top: 20px;
            }

            .social-icons {
                text-align: center;
                margin-top: 20px;

                img {
                    width: 30px;
                    height: 30px;
                    margin: 0 10px;
                }
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule, RouterModule,
        MatButtonModule, MatIconModule, MatMenuModule,
    ]
})
export class AppComponent {

    protected readonly location = location;

    isSmallScreen = true;

    constructor(
        private breakpointObserver: BreakpointObserver,
        public router: Router,
    ) {
    }

    ngOnInit() {
        this.breakpointObserver.observe('(max-width: 600px)').subscribe(result => {
            this.isSmallScreen = result.matches;
        })
    }
}
