import {Routes} from "@angular/router";

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./app/landing.component').then(m => m.LandingComponent)
    },
    {
        path: 'privacy-policy',
        loadComponent: () => import('./app/privacy-policy.component').then(m => m.PrivacyPolicyComponent)
    },
    {
        path: 'terms-of-service',
        loadComponent: () => import('./app/terms-of-service.component').then(m => m.TermsOfServiceComponent)
    },
    {
        path: 'delete-my-account',
        loadComponent: () => import('./app/delete-account.component').then(m => m.DeleteMyAccountComponent)
    },
    {
        path: 'how-tos',
        loadComponent: () => import('./app/how-tos.component').then(m => m.HowTosComponent)},
    {
        path: 'how-tos/:slug', loadComponent: () => import('./app/pages/page.component').then(m => m.PageComponent),
        data: {
            baseBreadcrumbs: [{name: $localize`How-tos`, url: '/how-tos'}]
        }
    },
    {
        path: 'features',
        loadComponent: () => import('./app/features.component').then(m => m.FeaturesComponent)
    },
    {
        path: 'features/:slug',
        loadComponent: () => import('./app/pages/page.component').then(m => m.PageComponent),
        data: {
            baseBreadcrumbs: [{name: $localize`Features`, url: '/features'}]
        }
    },
    {
        path: 'roadmap',
        loadComponent: () => import('./app/roadmap.component').then(m => m.RoadmapComponent)
    },
    {
        path: 'contact',
        loadComponent: () => import('./app/pages/page.component').then(m => m.PageComponent),
        data: {
            slug: 'contact',
        }
    },

    {path: '**', redirectTo: ''}
];
